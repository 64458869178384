import { useRef, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Unstable_Grid2";
import Carousel from "react-material-ui-carousel";
import { Box, TextField, Button, Paper, Typography } from "@mui/material";
import {
  ArrowCircleLeftOutlined,
  ArrowCircleRightOutlined,
  AttachFileOutlined,
  BlockOutlined,
  SendOutlined,
} from "@mui/icons-material";

import { AppAlert } from "../appAlert/AppAlert";
import { ProductListing as PathmaticsProductListing } from "../../pathmatics/productListing/ProductListing";
import { ProductListing as BisProductListing } from "../../bis/productListing/ProductListing";

import useMappingKeyStatus from "../../../hooks/common/useMappingKeyStatus";
import { useValidVisual } from "../../../hooks/pathmatics/useValidVisual";

import {
  ACTIVATION_FILTER_OPTIONS,
  MAPPING_OPTIONS,
  MAPPING_KEYS_STATUSES,
  CREATIVES_MEDIA_DOMAIN,
  DATA_SOURCE,
} from "../../../constants";

import { initLocalization } from "../../../utils/helpers";
import { updateCreativeStrings } from "./locale";
import { productListingStrings } from "../../pathmatics/productListing/locale";
import mediaPlaceholderImage from "../../../assets/media-placeholder.jpg";
import "./style.scss";

const BUFFER_SIZE = 7;

const buildCreatives = (data) => {
  const dataCopy = JSON.parse(JSON.stringify(data));
  dataCopy.forEach((item, idx) => (item.index = idx));
  return dataCopy;
};

const buildCreative = (index, slideIndex, data) => {
  return {
    index,
    slideIndex,
    id: data[index]?.id,
    prodId: data[index]?.product?.id,
    productId: data[index]?.product?.sourceProductId,
    status: data[index]?.status,
    isValidVisual: data[index]?.isValidVisual,
  };
};

const createChunk = (currentIndex, dataSource, offset = 0) => {
  let chunk = [];
  const prevBufferLastIndex = currentIndex - BUFFER_SIZE;
  const nextBufferLastIndex = currentIndex + BUFFER_SIZE;

  // prev buffer
  if (prevBufferLastIndex < 0) {
    chunk = [
      ...chunk,
      ...dataSource.slice(prevBufferLastIndex),
      ...dataSource.slice(0, BUFFER_SIZE + prevBufferLastIndex),
    ];
  } else {
    chunk = [...chunk, ...dataSource.slice(prevBufferLastIndex, currentIndex)];
  }

  // next buffer
  if (nextBufferLastIndex >= dataSource.length) {
    chunk = [
      ...chunk,
      ...dataSource.slice(currentIndex, nextBufferLastIndex),
      ...dataSource.slice(0, nextBufferLastIndex + 1 - dataSource.length),
    ];
  } else {
    chunk = [
      ...chunk,
      ...dataSource.slice(currentIndex, nextBufferLastIndex + 1),
    ];
  }

  // chunk offsetting
  // Note: due to the fact that the carousel's index prop doesn't work correctly
  // when the same value is passed to it, the chunk array has to be offset by
  // one item alternatively. The original array index will be kept in chunkIndex.
  chunk = JSON.parse(JSON.stringify(chunk));

  chunk.forEach((item, idx) => (item.chunkIndex = idx));

  const offsetChunk = [...chunk.slice(offset), ...chunk.slice(0, offset)];

  return offsetChunk;
};

export const UpdateCreative = ({
  dataSource,
  creativeDetailsData,
  onClose,
}) => {
  initLocalization(updateCreativeStrings);

  const chunkOffset = useRef(0);
  const videoRef = useRef(null);
  const [error, setError] = useState(null);
  const [creatives, setCreatives] = useState(
    buildCreatives(creativeDetailsData) || [],
  );
  const [selectedProduct, setSelectedProduct] = useState({});
  const [paperClipLabel, setPaperClipLabel] = useState("");

  const useChunks = creatives.length > 2 * BUFFER_SIZE + 1;

  const [creative, setCreative] = useState(
    buildCreative(
      0,
      useChunks ? BUFFER_SIZE - chunkOffset.current : 0,
      creatives,
    ),
  );

  const [creativesChunk, setCreativesChunk] = useState(
    useChunks ? createChunk(0, creatives) : creatives,
  );

  const {
    isLoading: actionLoading,
    paperClipMappingKey,
    rejectMappingKey,
    validateMappingKey,
  } = useMappingKeyStatus(
    MAPPING_OPTIONS[`${dataSource?.toLowerCase()}Creative`],
  );

  const { isLoading: validVisualLoading, setValidVisual } = useValidVisual();

  const disablePaperClipButton = () => {
    return !creatives?.length || !paperClipLabel || actionLoading;
  };

  const disableRejectButton = () => {
    return !creatives?.length || actionLoading;
  };

  const disableValidateButton = () => {
    return !creatives?.length || !selectedProduct.id || actionLoading;
  };

  const updateCarousel = (remove = false) => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current = null;
    }

    let nextIndex = creative.index + 1;

    if (nextIndex >= creatives.length) {
      nextIndex = 0;
    }

    const newCreatives = buildCreatives(
      remove
        ? [...creatives].filter((item) => item.id !== creative.id)
        : [...creatives],
    );

    const nextCreativeIndex = newCreatives.findIndex(
      (item) => item.id === creatives[nextIndex]?.id,
    );

    let nextChunkIndex;

    if (useChunks) {
      const nextChunkCreative = creativesChunk.find(
        (item) => item.id === newCreatives[nextCreativeIndex].id,
      );

      let chunkIndex = nextChunkCreative.chunkIndex;

      if (
        chunkIndex + 1 >= creativesChunk.length ||
        chunkIndex - 1 < 0 ||
        remove
      ) {
        const offset = 1 - chunkOffset.current;

        chunkOffset.current = offset;
        chunkIndex = BUFFER_SIZE;

        setCreativesChunk(
          createChunk(
            remove
              ? newCreatives[nextCreativeIndex].index
              : nextChunkCreative.index,
            newCreatives,
            offset,
          ),
        );
      }

      nextChunkIndex = chunkIndex - chunkOffset.current;
    } else if (remove) {
      setCreativesChunk(newCreatives);
    }

    const newCreative = buildCreative(
      nextCreativeIndex,
      useChunks ? nextChunkIndex : nextCreativeIndex,
      newCreatives,
    );

    if (!newCreatives.length) {
      onClose();
      return;
    }

    setCreatives(newCreatives);
    setCreative(newCreative);
  };

  const buildPayload = (creative, selectedProductId) => {
    const payload = {
      lineIds: [creative.id],
    };

    if (selectedProductId) payload.productId = selectedProductId;

    return payload;
  };

  const handlePaperClipFieldChange = (e) => {
    setPaperClipLabel(e.target.value);
  };

  const handlePaperClipMappingKeys = () => {
    const data = {
      ...buildPayload(creative),
      paperclipLabel: paperClipLabel,
    };
    paperClipMappingKey(data)
      .then(() => updateCarousel(true))
      .catch((err) => setError(err));
  };

  const handleRejectMappingKeys = () => {
    const data = buildPayload(creative);
    rejectMappingKey(data)
      .then(() =>
        updateCarousel(creative.status !== MAPPING_KEYS_STATUSES.rejected),
      )
      .catch((err) => setError(err));
  };

  const handleValidateMappingKeys = () => {
    const data = buildPayload(creative, selectedProduct.id);
    validateMappingKey(data)
      .then(() => updateCarousel(creative.prodId !== selectedProduct.id))
      .catch((err) => setError(err));
  };

  const handleSetValidVisual = () => {
    const isValidVisual = !creative.isValidVisual;
    setValidVisual({
      id: creative.id,
      isValidVisual,
    }).then(() => {
      setCreative((prev) => {
        const next = { ...prev };
        next.isValidVisual = isValidVisual;
        return next;
      });
      setCreatives((prev) => {
        const next = [...prev];
        next.find((item) => item.id === creative.id).isValidVisual =
          isValidVisual;
        return next;
      });
    });
  };

  const handleCloseError = () => setError(false);

  return (
    <>
      <AppAlert
        key={error}
        open={Boolean(error)}
        type="error"
        onClose={handleCloseError}
      >
        {productListingStrings.errors[error]}
      </AppAlert>

      <Grid
        className="update-creative"
        container
        rowSpacing={{ xs: 3, md: 0 }}
        columnSpacing={{ xs: 0, md: 2 }}
        py={6}
        px={{ xs: 2, md: 6 }}
        disableEqualOverflow
      >
        <Grid
          md={
            creative.status !== MAPPING_KEYS_STATUSES.waitingProductRequested
              ? 6
              : 12
          }
          xs={12}
        >
          <Paper
            sx={{ padding: "0.5rem", height: "100%", position: "relative" }}
          >
            <Carousel
              className="update-creative__carousel-wrapper"
              index={creative.slideIndex}
              animation="slide"
              autoPlay={false}
              navButtonsAlwaysVisible={true}
              fullHeightHover={false}
              onChange={(current, prev) => {
                if (videoRef.current) {
                  videoRef.current.pause();
                  videoRef.current = null;
                }

                let nextChunkIndex;

                if (useChunks) {
                  let chunkIndex = creativesChunk[current].chunkIndex;

                  if (
                    chunkIndex + 1 >= creativesChunk.length ||
                    chunkIndex - 1 < 0
                  ) {
                    const offset = 1 - chunkOffset.current;

                    chunkOffset.current = offset;
                    chunkIndex = BUFFER_SIZE;

                    setCreativesChunk(
                      createChunk(
                        creativesChunk[current].index,
                        creatives,
                        offset,
                      ),
                    );
                  }

                  nextChunkIndex = chunkIndex - chunkOffset.current;
                }

                setCreative(
                  buildCreative(
                    creativesChunk[current].index,
                    useChunks ? nextChunkIndex : current,
                    creatives,
                  ),
                );
              }}
              navButtonsWrapperProps={{
                className: "update-creative__nav-buttons-wrapper-props",
              }}
              navButtonsProps={{
                className: "update-creative__nav-buttons-props",
                disabled: creatives?.length <= 1 || actionLoading,
              }}
              PrevIcon={
                <Box className="update-creative__slider-buttons update-creative__slider-prev">
                  <ArrowCircleLeftOutlined
                    sx={{ width: "2.5rem", height: "2.5rem" }}
                  />
                  {updateCreativeStrings.prevButton}
                </Box>
              }
              NextIcon={
                <Box className="update-creative__slider-buttons update-creative__slider-next">
                  {updateCreativeStrings.nextButton}
                  <ArrowCircleRightOutlined
                    sx={{ width: "2.5rem", height: "2.5rem" }}
                  />
                </Box>
              }
              IndicatorIcon={creativesChunk.map(
                (item) => `${item.index + 1} / ${creatives.length}`,
              )}
              indicatorIconButtonProps={{
                className: "update-creative__indicator-icon-button-props",
                disabled: true,
              }}
              activeIndicatorIconButtonProps={{
                className:
                  "update-creative__active-indicator-icon-button-props",
              }}
              indicatorContainerProps={{
                className: "update-creative__indicator-container-props",
              }}
            >
              {creativesChunk?.map((item) => (
                <div
                  key={item.id}
                  style={{ display: "flex", height: "calc(100vh - 250px)" }}
                >
                  <div className="creative-media-wrapper">
                    {item?.mediaTypeName?.length === 0 && (
                      <img
                        src={mediaPlaceholderImage}
                        alt={item.name}
                        className="creative-media-wrapper__media-placeholder"
                      />
                    )}
                    {item?.mediaTypeName === "image" && (
                      <img
                        src={item?.location}
                        alt={item.name}
                        className="creative-media-wrapper__media-image"
                      />
                    )}
                    {item?.mediaTypeName === "video" && (
                      <video
                        className="creative-media-wrapper__media-video"
                        controls
                        muted
                        onPlay={(e) => (videoRef.current = e.target)}
                      >
                        <source src={item?.location} />
                      </video>
                    )}
                    {item?.mediaTypeName?.toLowerCase() === "html5" && (
                      <iframe
                        className="creative-media-wrapper__media-iframe"
                        title={item?.id}
                        src={item?.location?.split(CREATIVES_MEDIA_DOMAIN)?.[1]}
                      />
                    )}
                    {dataSource === DATA_SOURCE.pathmatics && (
                      <div className="creative-media-wrapper__media-description">
                        <Typography sx={{ fontWeight: "bold" }}>
                          {updateCreativeStrings.description}
                        </Typography>
                        <Typography>{item?.creativeText}</Typography>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </Carousel>
          </Paper>
        </Grid>
        {creative.status !== MAPPING_KEYS_STATUSES.waitingProductRequested && (
          <Grid md={6} xs={12}>
            <Grid container direction="column" columns={12} height="100%">
              <Grid xs={12} flexGrow="1">
                {dataSource === DATA_SOURCE.pathmatics ? (
                  <PathmaticsProductListing
                    activationFilterOption={ACTIVATION_FILTER_OPTIONS.creatives}
                    mappingKey={creative}
                    allowSearch={true}
                    onSelectedProductRow={setSelectedProduct}
                    onSetValidVisual={handleSetValidVisual}
                    shouldRefresh={1}
                    isLoading={actionLoading || validVisualLoading}
                  />
                ) : (
                  <BisProductListing
                    activationFilterOption={ACTIVATION_FILTER_OPTIONS.creatives}
                    mappingKey={creative}
                    allowSearch={true}
                    onSelectedProductRow={setSelectedProduct}
                    shouldRefresh={1}
                    isLoading={actionLoading || validVisualLoading}
                  />
                )}
              </Grid>
              <Grid
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                gap={2}
                paddingY={3}
                justifyContent="space-between"
              >
                <Grid
                  display="flex"
                  flexDirection={{ xs: "column", sm: "row" }}
                  gap={2}
                >
                  <TextField
                    id="outlined-disabled"
                    label={updateCreativeStrings.paperClipLabel}
                    size="small"
                    value={paperClipLabel}
                    inputProps={{ maxLength: 255 }}
                    onChange={handlePaperClipFieldChange}
                  />
                  <Button
                    startIcon={<AttachFileOutlined />}
                    variant="outlined"
                    size="large"
                    disabled={disablePaperClipButton()}
                    onClick={handlePaperClipMappingKeys}
                  >
                    {updateCreativeStrings.paperClip}
                  </Button>
                </Grid>
                <Grid
                  display="flex"
                  flexDirection={{ xs: "column", sm: "row" }}
                  gap={2}
                >
                  <Button
                    startIcon={<BlockOutlined />}
                    variant="outlined"
                    color="error"
                    size="large"
                    disabled={disableRejectButton()}
                    onClick={handleRejectMappingKeys}
                  >
                    {updateCreativeStrings.reject}
                  </Button>
                  <Button
                    startIcon={<SendOutlined />}
                    variant="outlined"
                    color="success"
                    size="large"
                    disabled={disableValidateButton()}
                    onClick={handleValidateMappingKeys}
                  >
                    {updateCreativeStrings.validate}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

UpdateCreative.propTypes = {
  dataSource: PropTypes.string.isRequired,
  creativeDetailsData: PropTypes.arrayOf(PropTypes.objectOf).isRequired,
  onClose: PropTypes.func.isRequired,
};
